@tailwind base;
@tailwind components;
@tailwind utilities;

.full_container {
  display: flex;
  /* padding: 50px 0px; */
}

.full_container .left_mu {
  width: 30%;
  padding-top: 20px;
}

.full_container .right_mu {
  width: 70%;
}

.full_container .right_mu .right_content {
  position: relative;
}

h3.h3_title {
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 10px;
}

.full_container .right_mu .right_content p,
.text_img p {
  font-size: 16px;
  padding-bottom: 15px;
}

.full_container .right_mu .right_content .text_img,
.text_img {
  display: flex;
  gap: 15px;
}

.full_container .right_mu .right_content .text_img .text_r,
.text_img .text_r {
  width: 53%;
}

.full_container .right_mu .right_content .text_img .img,
.text_img .img {
  width: 43%;
  padding-bottom: 10px;
}

.img_bottom_text {
  padding-bottom: 5px;
  padding-top: 5px;
}

.img_bottom_text span {
  display: block;
  font-size: 11px;
}

.img_bottom_text a {
  font-style: inherit;
}

.btn_c {
  display: inline-block;
  padding: 0.78571429em 1.5em;
  border: 1px solid rgb(255, 45, 92);
  box-shadow: none;
  color: rgb(255, 45, 92) !important;
  margin: 35px 0px;
}

.full_container .left_mu ul li {
  position: relative;
}

.full_container .left_mu ul li a {
  display: block;
  font-size: 12px;
  padding-bottom: 20px;
  line-height: 14px;
  letter-spacing: 0.04em;
  transition: 0.3s;
  color: rgb(137, 137, 137);
}

.full_container .left_mu ul li a:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: lightgrey;
  border-radius: 50%;
  left: -15px;
  top: 5px;
}

.full_container .left_mu ul li a.active,
.full_container .left_mu ul li a:hover {
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  color: rgb(7, 58, 77);
}

.full_container .left_mu ul li a.active:before,
.full_container .left_mu ul li a:hover:before {
  width: 13px;
  height: 13px;
  background: transparent;
  border: 1px solid;
  left: -18px;
  top: 7px;
}

.beliebte_container h3.h3_title,
.andere_container h3.h3_title {
  text-align: center;
  padding-bottom: 30px;
}

.beliebte_container {
  padding-bottom: 50px;
}

.beliebte_container .beliebte_row {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.beliebte_container .beliebte_row .belibite_items {
  padding: 20px;
  width: 25%;
  text-align: center;
}

.beliebte_container .beliebte_row .belibite_items a {
  border: 1px solid;
  padding: 12px 25px;
  border-radius: 25px;
  display: inline-block;
  background: rgb(238, 238, 238);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 22.5495px;
}

.beliebte_container .beliebte_row.last_row {
  justify-content: center;
}

.beliebte_container .beliebte_row .belibite_items a:hover {
  background: rgb(7, 58, 77);
  border-color: rgb(7, 58, 77);
  transition: 0.3s;
  color: #fff;
}

.andere_container .andere_row {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 35px;
  gap: 30px;
}

.andere_container .andere_row.last_row {
  justify-content: center;
  gap: 35px;
}

.andere_container .andere_row .andere_card {
  width: 23%;
  position: relative;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 18px;
}

.andere_container .andere_row .andere_card img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transform: scale(1);
  transition: transform 0.5s ease-in-out 0s;
}

.andere_container .andere_row .andere_card .andere_text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  color: rgb(255, 255, 255);
  user-select: none;
  transform: translate(-50%, -50%);
}

.andere_container .andere_row .andere_card .andere_link {
  /* position: relative; */
}

.andere_container .andere_row .andere_card .andere_link:before {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  content: "";
  cursor: pointer;
}

.andere_container .andere_row .andere_card:hover img {
  transform: scale(1.5);
  transition: transform 4s ease-in-out 0s;
}

.top_links {
  padding-top: 10px;
  padding-bottom: 70px;
  position: relative;
  left: -10px;
}

.top_links span {
  display: inline-block;
  position: relative;
  font-size: 12px;
}

.top_links span::after {
  content: ">";
}

.top_links span:last-child::after {
  content: "";
}

.beliebte_state_container {
  padding: 30px 0px 50px 0px;
}

.bg_color {
  background: rgb(244, 244, 244);
}

.beliebte_state_container .beliebte_state_row {
  display: flex;
  flex-wrap: wrap;
}

.beliebte_state_container .beliebte_state_row .beliebte_state_card {
  width: 20%;
}

.beliebte_state_container .beliebte_state_row .beliebte_state_card a {
  display: block;
  padding: 4px 0px;
}

.beliebte_state_container {
  padding: 30px 0px 50px 0px;
}

.beliebte_state_container .beliebte_state_row {
  display: flex;
  flex-wrap: wrap;
}

.beliebte_state_container .beliebte_state_row .beliebte_state_card {
  width: 20%;
}

.beliebte_state_container .beliebte_state_row .beliebte_state_card a {
  display: block;
  padding: 4px 0px;
}

.buchen_container {
  padding: 35px 0px;
  display: flex;
  background: #fff;
}

.buchen_container .buchen_left {
  background: linear-gradient(
    100.29deg,
    rgb(0 80 155) 2.08%,
    rgb(220 159 79) 96.31%
  );
  padding: 25px 50px 25px 100px;
  box-sizing: border-box;
  min-width: 400px;
  width: 100%;
  max-width: 560px;
  position: relative;
  overflow: hidden;
}

.buchen_container .buchen_right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 25px 20px 15px 25px;
  background: rgb(239, 239, 239);
}

.buchen_container .buchen_left h1 {
  font-weight: 800;
  font-size: 24px;
  line-height: 146.9%;
  color: rgb(220 159 79);
  margin: 0px;
}

.buchen_container .buchen_left p {
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: rgb(253 253 253);
  margin: 5px 0px 15px;
}

.buchen_container .buchen_left a {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220 159 79);
  border-radius: 28px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 156.5%;
  letter-spacing: 0.02em;
  color: rgb(220 159 79);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 128px;
  min-height: 34px;
  width: fit-content;
}

.buchen_container .buchen_right p {
  background: rgb(255, 255, 255);
  border-radius: 20.5px;
  padding: 8px 26px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgb(55, 124, 150);
}

.buchen_container .buchen_right .tools_options {
  padding-top: 30px;
  display: flex;
  gap: 20px;
}

.buchen_container .buchen_right .tools_options .t_items {
  width: 33%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.buchen_container .buchen_left:after {
  height: 500px;
  content: "";
  position: absolute;
  background: rgb(239, 239, 239);
  width: 85px;
  top: -165px;
  right: -58px;
  transform: rotate(16deg);
}

.beli_sate_bg {
  background: rgba(200, 200, 200, 0.09);
}

.whiteBox {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media only screen and (max-width: 992px) {
  .buchen_container .buchen_left:after {
    display: none;
  }

  .buchen_container {
    flex-wrap: wrap;
  }

  .buchen_container .buchen_left {
    max-width: 100%;
  }

  .full_container .right_mu .right_content .text_img,
  .text_img {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .full_container .right_mu .right_content .text_img .img,
  .text_img .img,
  .full_container .right_mu .right_content .text_img .text_r,
  .text_img .text_r {
    width: 100%;
  }

  .andere_container .andere_row .andere_card {
    width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .buchen_container .buchen_left {
    padding: 25px 50px 25px 35px;
  }

  .buchen_container .buchen_right .tools_options {
    flex-wrap: wrap;
  }

  .px-20.full_container {
    flex-wrap: wrap;
    padding: 0px 25px;
  }

  .buchen_container .buchen_right .tools_options .t_items {
    width: 100%;
  }

  .full_container .right_mu,
  .full_container .left_mu {
    width: 100%;
  }

  .top_links {
    left: 0;
    padding: 15px;
  }

  h3.h3_title {
    font-size: 25px;
  }

  .px-20.andere_container,
  .px-20.c_container_fluid.beli_sate_bg {
    padding: 0px 20px;
  }

  .andere_container .andere_row .andere_card {
    width: 100%;
  }

  .andere_container .andere_row {
    gap: 0px;
  }

  .beliebte_state_container .beliebte_state_row .beliebte_state_card {
    width: 33%;
  }
}

#signupphone > .react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border-radius: 0px;
  line-height: 25px;
  height: 40px;
  width: 300px;
  outline: none;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.react-tel-input .flag-dropdown {
  background-color: none;
  border: none;
  border-radius: none;
}

#passengerphone .PhoneInputInput {
  height: 2.3em;
  padding: 10px;
  --tw-border-opacity: 1;
  border: 2px solid;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.25rem;
}

#signupphone .PhoneInputInput {
  height: 2.3em;
  padding: 10px;
  --tw-border-opacity: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 2px;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  width: 17.5rem;
}

#signupphone input:focus {
  outline: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 2px;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

#passengerphone input:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.agent-flight th,
.agent-flight td {
  text-align: left;
  padding: 8px;
}

.agent-flight-page .agent-flight tr:nth-child(even) {
  background-color: rgb(209 213 219);
}

.ReactModal__Content--after-open {
  top: 100px !important;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-btn.ant-btn-sm {
  background-color: blue !important;
  color: white;
}

.respond-status {
  display: flex;
  justify-content: end;
  padding: 10px 0;
}
.respond-status-inner {
  display: flex;
  gap: 5px;
}
.respond-status-inner div {
  cursor: pointer;
  font-weight: 500;
}
.respond-status-inner div:hover {
  color: rgb(202 138 4);
}
