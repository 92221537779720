.my-job-page {
}

.my-job-page .ReactModal__Overlay,
.ReactModal__Overlay--after-open {
  background-color: #00000080 !important;
  background: #00000080 !important;
  z-index: 100 !important;
}
.box-block {
  min-height: 570px;
  margin-top: -210px;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 14px;
  box-shadow: 0px 0px 5px 0px #dddddd;
}

@media (max-width: 480px) {
  .box-block {
    margin-left: 14px;
    margin-right: 14px;
  }
}
.icon-shadow {
  box-shadow: 0px 0px 4px 0px #00000040;
}

.bgImage {
  height: 280px;
  width: 100%;
  background-image: url("../../../public/bgDots.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.otp-input {
}
.otp-input .vi__character {
  border-radius: 8px !important;
  height: 60px !important;
}
.otp-input .vi__character--selected {
  border: 1px solid #ebad18 !important;
  outline: #ebad18;
  background: #fffaed !important;
}
.otp-input .vi__character--inactive {
  border: 1px solid #e5e5e5 !important;
  outline: #e5e5e5;
  background: #fafafa !important;
}

:where(.vi__container) {
  height: 100% !important;
  width: 100% !important;
}

.button {
  padding: 10px 20px;
  transition: background-color 0.5s ease;
}

.button .arrow-icon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s ease;
}
.button.clicked {
  background: #000 !important;
  background-color: #000 !important;
  color: #fafafa !important;
}
.button.clicked h1 {
  color: #fafafa !important;
}
.button.clicked .arrow-icon {
  left: calc(100% + -50px);
}
