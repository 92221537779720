.full_container {
  display: flex;
  /* padding: 50px 0px; */
}

.full_container .left_mu {
  width: 30%;
  padding-top: 20px;
}

.full_container .right_mu {
  width: 70%;
}

.full_container .right_mu .right_content {
  position: relative;
}

h3.h3_title {
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 10px;
}

.full_container .right_mu .right_content p,
.text_img p {
  font-size: 16px;
  padding-bottom: 15px;
}

.full_container .right_mu .right_content .text_img,
.text_img {
  display: flex;
  gap: 15px;
}

.full_container .right_mu .right_content .text_img .text_r,
.text_img .text_r {
  width: 53%;
}

.full_container .right_mu .right_content .text_img .img,
.text_img .img {
  width: 43%;
  padding-bottom: 10px;
}

.img_bottom_text {
  padding-bottom: 5px;
  padding-top: 5px;
}

.img_bottom_text span {
  display: block;
  font-size: 11px;
}

.img_bottom_text a {
  font-style: inherit;
}

.btn_c {
  display: inline-block;
  padding: 0.78571429em 1.5em;
  border: 1px solid rgb(255, 45, 92);
  box-shadow: none;
  color: rgb(255, 45, 92) !important;
  margin: 35px 0px;
}

.full_container .left_mu ul li {
  position: relative;
}

.full_container .left_mu ul li a {
  display: block;
  font-size: 12px;
  padding-bottom: 20px;
  line-height: 14px;
  letter-spacing: 0.04em;
  transition: 0.3s;
  color: rgb(137, 137, 137);
}

.full_container .left_mu ul li a:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: lightgrey;
  border-radius: 50%;
  left: -15px;
  top: 5px;
}

.full_container .left_mu ul li a.active,
.full_container .left_mu ul li a:hover {
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  color: rgb(7, 58, 77);
}

.full_container .left_mu ul li a.active:before,
.full_container .left_mu ul li a:hover:before {
  width: 13px;
  height: 13px;
  background: transparent;
  border: 1px solid;
  left: -18px;
  top: 7px;
}

.beliebte_container h3.h3_title,
.andere_container h3.h3_title {
  text-align: center;
  padding-bottom: 30px;
}

.beliebte_container {
  padding-bottom: 50px;
}

.beliebte_container .beliebte_row {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.beliebte_container .beliebte_row .belibite_items {
  padding: 20px;
  width: 25%;
  text-align: center;
}

.beliebte_container .beliebte_row .belibite_items a {
  border: 1px solid;
  padding: 12px 25px;
  border-radius: 25px;
  display: inline-block;
  background: rgb(238, 238, 238);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 22.5495px;
}

.beliebte_container .beliebte_row.last_row {
  justify-content: center;
}

.beliebte_container .beliebte_row .belibite_items a:hover {
  background: rgb(7, 58, 77);
  border-color: rgb(7, 58, 77);
  transition: 0.3s;
  color: #fff;
}

.andere_container .andere_row {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 35px;
  gap: 30px;
}

.andere_container .andere_row.last_row {
  justify-content: center;
  gap: 35px;
}

.andere_container .andere_row .andere_card {
  width: 23%;
  position: relative;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 18px;
}

.andere_container .andere_row .andere_card img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transform: scale(1);
  transition: transform 0.5s ease-in-out 0s;
}

.andere_container .andere_row .andere_card .andere_text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  color: rgb(255, 255, 255);
  user-select: none;
  transform: translate(-50%, -50%);
}

.andere_container .andere_row .andere_card .andere_link {
  /* position: relative; */
}

.andere_container .andere_row .andere_card .andere_link:before {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  content: "";
  cursor: pointer;
}

.andere_container .andere_row .andere_card:hover img {
  transform: scale(1.5);
  transition: transform 4s ease-in-out 0s;
}

.top_links {
  padding-top: 10px;
  padding-bottom: 70px;
  position: relative;
  left: -10px;
}

.top_links span {
  display: inline-block;
  position: relative;
  font-size: 12px;
}

.top_links span::after {
  content: ">";
}

.top_links span:last-child::after {
  content: "";
}

.beliebte_state_container {
  padding: 30px 0px 50px 0px;
}

.bg_color {
  background: rgb(244, 244, 244);
}

.beliebte_state_container .beliebte_state_row {
  display: flex;
  flex-wrap: wrap;
}

.beliebte_state_container .beliebte_state_row .beliebte_state_card {
  width: 20%;
}

.beliebte_state_container .beliebte_state_row .beliebte_state_card a {
  display: block;
  padding: 4px 0px;
}

.beliebte_state_container {
  padding: 30px 0px 50px 0px;
}

.beliebte_state_container .beliebte_state_row {
  display: flex;
  flex-wrap: wrap;
}

.beliebte_state_container .beliebte_state_row .beliebte_state_card {
  width: 20%;
}

.beliebte_state_container .beliebte_state_row .beliebte_state_card a {
  display: block;
  padding: 4px 0px;
}

.buchen_container {
  padding: 35px 0px;
  display: flex;
  background: #fff;
}

.buchen_container .buchen_left {
  background: linear-gradient(
    100.29deg,
    rgb(0 80 155) 2.08%,
    rgb(220 159 79) 96.31%
  );
  padding: 25px 50px 25px 100px;
  box-sizing: border-box;
  min-width: 400px;
  width: 100%;
  max-width: 560px;
  position: relative;
  overflow: hidden;
}

.buchen_container .buchen_right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 25px 20px 15px 25px;
  background: rgb(239, 239, 239);
}

.buchen_container .buchen_left h5 {
  font-weight: 800;
  font-size: 24px;
  line-height: 146.9%;
  color: rgb(220 159 79);
  margin: 0px;
}

.buchen_container .buchen_left p {
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: rgb(253 253 253);
  margin: 5px 0px 15px;
}

.buchen_container .buchen_left a {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220 159 79);
  border-radius: 28px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 156.5%;
  letter-spacing: 0.02em;
  color: rgb(220 159 79);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 128px;
  min-height: 34px;
  width: fit-content;
}

.buchen_container .buchen_right p {
  background: rgb(255, 255, 255);
  border-radius: 20.5px;
  padding: 8px 26px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgb(55, 124, 150);
}

.buchen_container .buchen_right .tools_options {
  padding-top: 30px;
  display: flex;
  gap: 20px;
}

.buchen_container .buchen_right .tools_options .t_items {
  width: 33%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.buchen_container .buchen_left:after {
  height: 500px;
  content: "";
  position: absolute;
  background: rgb(239, 239, 239);
  width: 85px;
  top: -165px;
  right: -58px;
  transform: rotate(16deg);
}

.beli_sate_bg {
  background: rgba(200, 200, 200, 0.09);
}

.whiteBox {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* --- */
.tabs-button {
  padding: 25px 0px;
  text-align: center;
}
.tabs-button .tabs-btn {
  padding: 10px 25px;
  border: 1px solid #ccc;
  font-weight: 500;
}
.tabs-button .tabs-btn.active {
  background-color: #ca8a04;
  border-color: #ca8a04;
}
.tabs-button .tabs-btn + .tabs-btn {
  margin-left: 15px;
}
/* .flight-card{
    max-width: 550px;
    margin: auto;
}
.flight-card h3{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
} */
/* .input-wrapper{
    margin-bottom: 15px;
} */
/* .input-wrapper input{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}
.input-wrapper input::placeholder{
    color: #000;
} */
.btn-get-offer {
  padding: 25px 0px;
}
.btn-get-offer button {
  display: block;
  padding: 7px 35px;
  border-radius: 50px;
  background-color: #ca8a04;
  font-size: 15px;
  width: 100%;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;
}
.btn-get-offer button:hover {
  background-color: #000;
}
.input-changer {
  display: flex;
  justify-content: center;
}
.flight-inputs svg.cursor-pointer {
  margin: -10px 0px;
}
.input-changer svg.cursor-pointer {
  border: 1px solid;
  padding: 3px;
  border-radius: 50%;
  transform: rotate(90deg);
  background-color: #fff;
}
.flight-details-section {
  display: flex;
}

.flight-details-section .left-details,
.flight-details-section .right-map {
  width: 100%;
  padding: 0px 15px;
  padding-bottom: 50px;
}
.flight-details-section .left-details {
  padding-right: 100px;
}
.textarea-wrap textarea {
  width: 100%;
  border-bottom: 1px solid;
  resize: none;
  padding: 10px 0px;
  height: 48px;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}
.textarea-wrap textarea::placeholder {
  color: #000;
}
.catch-details .input-changer svg.cursor-pointer {
  margin-top: -13px;
  background: #fff;
}
.point {
  padding: 1px;
  border-radius: 50%;
  display: block;
  width: 25px;
  height: 25px;
  text-align: center;
  background-color: #ca8a04;
  color: #fff;
}
.textarea-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.arrow-down {
  position: relative;
}
.arrow-down svg {
  position: absolute;
  left: 0px;
  transform: rotate(90deg);
}
.offer-inputs {
  padding-top: 35px;
  padding-left: 35px;
}
.offer-inputs input {
  width: 100%;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}
input[type="date"] {
  text-transform: uppercase;
}
.passengers-inputs,
.catch-details .btn-get-offer {
  padding-left: 35px;
}
.catch-details .btn-get-offer button {
  width: auto;
  margin: auto;
}
.passengers-inputs .a-row {
  border-bottom: 1px solid;
}
.card-flight {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 15px 0px;
  overflow: hidden;
}
.card-flight .left-wrap {
  width: 38%;
  display: flex;
  gap: 35px;
  align-items: center;
  min-width: 114px;
}
.card-flight .right-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 35px;
  min-width: 114px;
}

.card-flight .ex-right {
  width: 33%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 114px;
}

.passengers-inputs {
  padding-left: 35px;
}
.passengers-inputs .a-row {
  border-bottom: 1px solid;
}
.card-flight {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}

.card-flight .left-wrap .img-star {
  width: 45%;
  position: relative;
}
.card-flight .left-wrap .img-star .star {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #ca8a04;
  display: flex;
  gap: 5px;
  align-items: center;
  padding-left: 10px;
  color: #fff;
}
.card-flight .left-wrap .card-details label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.card-flight .left-wrap .card-details .flight-name {
  padding: 7px 0px;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 700;
}

.card-flight .left-wrap .card-details .facility {
  display: flex;
  gap: 10px;
  padding-top: 10px;
}

.card-flight .left-wrap .card-details .facility svg {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.card-flight .right-wrap .flags img {
  width: 35px;
  height: 25px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}
.card-flight .right-wrap .flags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 35%;
}
.booking-time-section {
  display: flex;
  justify-content: space-between;
  padding: 50px 35px;
}
.booking-time-section .right-action button {
  border: 1px solid;
  padding: 7px 25px;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}
.booking-time-section .right-action button + button {
  margin-left: 10px !important;
}
button.btn-repeat {
  background: #37a337;
}
button.btn-details {
  background: #ca8a04;
}
button.btn-cancel {
  background: #d91212;
}
.passengers-inputs .title-bar {
  border-bottom: 1px solid;
  padding-bottom: 10px;
}
.cancel-link {
  color: #ff0000;
}
/* --- */
.flighttiming-details-row {
  display: flex;
  gap: 20px;
}

.left-details .time-div-flight {
  text-align: right;
}

.time-div-flight h5 {
  font-size: 23px;
  font-weight: 500;
}
.airplane-line {
  position: relative;
}

.airplane-line .bordr-line {
  display: block;
  width: 100%;
  height: 2px;
  background: #ccc;
  margin: 8px 0px;
}

.airplane-line .red-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ff0000;
  border-radius: 50%;
  left: 57%;
  top: -3px;
}
.flighttiming-details-row .bottom-line {
  text-align: center;
}
.flighttiming-details-row .bottom-line span {
  color: #ff0000;
}
.flighttiming-details-row .middle-details .flight-icon {
  width: 25px;
  transform: rotate(90deg);
  position: absolute;
  right: 0px;
  top: -11px;
  background: #fff;
}
.inputs-wrapper-row {
  display: flex;
  gap: 5px;
  background: #1f2937;
  padding: 20px 15px;
}

.inputs-wrapper-row .input-wrap {
  padding: 5px;
  width: 14%;
  background: #fff;
}

.inputs-wrapper-row .input-wrap input {
  width: 100%;
  font-size: 14px;
  padding: 2px 5px;
  margin-top: 5px;
  background: #cccccc4d;
}

.inputs-wrapper-row .input-wrap label {
  font-weight: 500;
  text-transform: uppercase;
}
.btn-serach {
  flex: 1;
}

.btn-serach button {
  background: #ca8a04;
  width: 100%;
  height: 100%;
  font-size: 22px;
  text-transform: uppercase;
  padding: 5px;
}
.luggage-row {
  padding: 10px 0px;
}
.luggage-row input {
  width: 150px;
  border: 1px solid;
  padding: 5px;
  color: #000;
}
.luggage-row input::placeholder {
  color: #000;
}
.top-search-bar {
  padding-bottom: 50px;
}
.card-flight .right-wrap .rate {
  display: flex;
  gap: 12px;
}
/* --- */

@media only screen and (max-width: 992px) {
  .buchen_container .buchen_left:after {
    display: none;
  }

  .buchen_container {
    flex-wrap: wrap;
  }

  .buchen_container .buchen_left {
    max-width: 100%;
  }

  .full_container .right_mu .right_content .text_img,
  .text_img {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .full_container .right_mu .right_content .text_img .img,
  .text_img .img,
  .full_container .right_mu .right_content .text_img .text_r,
  .text_img .text_r {
    width: 100%;
  }

  .andere_container .andere_row .andere_card {
    width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .buchen_container .buchen_left {
    padding: 25px 50px 25px 35px;
  }

  .buchen_container .buchen_right .tools_options {
    flex-wrap: wrap;
  }

  .px-20.full_container {
    flex-wrap: wrap;
    padding: 0px 25px;
  }

  .buchen_container .buchen_right .tools_options .t_items {
    width: 100%;
  }

  .full_container .right_mu,
  .full_container .left_mu {
    width: 100%;
  }

  .top_links {
    left: 0;
    padding: 15px;
  }

  h3.h3_title {
    font-size: 25px;
  }

  .px-20.andere_container,
  .px-20.c_container_fluid.beli_sate_bg {
    padding: 0px 20px;
  }

  .andere_container .andere_row .andere_card {
    width: 100%;
  }

  .andere_container .andere_row {
    gap: 0px;
  }

  .beliebte_state_container .beliebte_state_row .beliebte_state_card {
    width: 33%;
  }
}

#signupphone > .react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border-radius: 0px;
  line-height: 25px;
  height: 40px;
  width: 300px;
  outline: none;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.react-tel-input .flag-dropdown {
  background-color: none;
  border: none;
  border-radius: none;
}

#passengerphone .PhoneInputInput {
  height: 2.3em;
  padding: 10px;
  --tw-border-opacity: 1;
  border: 2px solid;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.25rem;
}

#signupphone .PhoneInputInput {
  height: 2.3em;
  padding: 10px;
  --tw-border-opacity: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 2px;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  width: 17.5rem;
}

#signupphone input:focus {
  outline: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 2px;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

#passengerphone input:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

@media screen and (max-width: 1000px) {
  .top-search-bar .inputs-wrapper-row {
    flex-direction: column;
  }
  .top-search-bar .inputs-wrapper-row .input-wrap {
    width: 100%;
    padding: 5px 5px 8px 5px;
  }
}

.passengers_drop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid hsl(0, 0%, 80%);
  height: 38px;
  border-radius: 4px;
  padding: 4px;
  color: hsl(0, 8%, 72%);
  align-items: center;
}
.passenger_drop-main {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: white;
  position: absolute;
  height: 70px;
  width: 95%;
  padding: 10px;
}

.counter-part {
  display: flex;
  gap: 5px;
  align-items: center;
}
