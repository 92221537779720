/**/

.tooltip {
   
    display: inline-block;
    /*border-bottom: 1px dotted #ccc;
      color: #006080; */
      
  }
  
 .tooltip:hover{
    z-index: 99999;
 }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: max-content;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    background-color: #ffffff;

    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  .tooltip-right {
    top: -5px;
    left: 125%;
  }
  
  .tooltip-right::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
  
  .tooltip-bottom {
    top: 135%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip-bottom::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent #55555530 transparent;
  }
  
  .tooltip-top {
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip-top::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip-left {
    top: -5px;
    bottom: auto;
    right: 128%;
  }
  .tooltip-left::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
  }
  
  .arrow {
    position: absolute;
    left: 48%;
    top: -10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #fff;
    z-index: 1;
  }